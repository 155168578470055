/* single filter divs */

.filter-title {
	/*background: #00ff00;*/
	margin-top: 5px;
	margin-bottom: 5px;
	color: black;
	font-size: 12px;
}

.filter-body {
	/*background: #0000ff;*/
}

/* search - edit field */

.filter-search {
}
