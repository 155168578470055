.App {
	background-color: #ffffff;
	min-height: 100vh;
	max-height: 100vh;
	min-width: 100vw;
	max-width: 100vw;
	/*	font-size: calc(8px + 1vmin); */
	font-size: 12px;
	color: white;
	margin: 0px;
	padding: 0px;
}

.report {
	height: 40vh;
}

.reports {
	background-color: #bbbbbb;
	width: 100%;
	padding-left: 230px;
	padding-right: 0px;
	text-align: center;
}

.container {
	margin:0px;
	padding: 0px 0px 0px;
	text-align: left;
}

.col {
	background-color: #ffffff;
	color: #000;
	font-size: calc(12px);
}

.col-3 {
	background-color: #ffffff;
	color: #000;
	font-size: calc(12px);
}

.col-7 {
	background-color: #ffffff;
	color: #000;
	font-size: calc(12px);
}

.loadingcontainer {
	width: 100%;
	height: 80px;
	align-items: center;
}

.loading {
	width: 50px;
	height: 50px;
}

.btn {
	margin-bottom:0px;
	font-size: 14px;
}

/*.btn-primary {
	background-color: #cccccc;
	border-color: #cccccc;
	color: #000000;
}*/

/*.btn-primary:disabled {
	background-color: #0069d9;
	border-color: #0069d9;
	color: #ffffff;
}*/

.datediv {
	margin-top:20px;
	margin-bottom:20px;
	display:block;
}

.superuserdiv {
	margin-top:20px;
	margin-bottom:20px;
	display:block;
}

.datediv p {
	font-size: 14px;
	margin:20px 0px 0px;
	color: #000000;
}

.periodForm {
    text-align: left;
    margin-left: 20px;
}

.appIdentifierForm {
    margin-top: 20px;
    text-align: left;
    margin-left: 20px;
}

.App-link {
	color: #61dafb;
}

.caret {
display: inline-block;
width: 0;
height: 0;
margin-left: 2px;
vertical-align: middle;
border-top: 4px dashed;
border-top: 4px solid \9;
border-right: 4px solid transparent;
border-left: 4px solid transparent;
}

.dropup, .dropdown {
position: relative;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
border-top: 0;
border-bottom: 4px dashed;
border-bottom: 4px solid \9;
content: "";
}

@media print{
	.header {
		display:none !important;
	}
	.sidebar {
		display:none !important;
	}
	.App {
		display: block !important;
	}
	div {
		display: block !important;
	}
	body {
		display: block !important;
		overflow: visible !important;
		overflow-x: visible !important;
		overflow-y: visible !important;
	}
	.reports {
		display: block !important;
		padding-left: 0px !important;
		margin-left: 0px !important;
	}
}

.btn-sm {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
	margin-top: 0px;
}

.logoutButton {
	display: inline;
	margin-left: 20px;
}

.header {
	background-color: #afafaf;
	height: 40px;
	#min-width: 100vw;
	#max-width: auto;
	text-align: center;
        z-index: 3000;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.headerlogininfo {
	width: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left:auto;
	margin-right:20px;
	align-items: center;
	align-content: flex-end;
	color: #000;
	float:left;
}

.sidebarfilters {
	margin-top:10px;
	margin-bottom:10px;
	color: #000;
	align-items: center;
}

.sidebarfilters button {
	margin: 5px;
}

.container {
	margin: 0px;
	padding: 0px;
	xmin-height: 100vh;
	xmax-height: 100vh;
	min-width: 100vw;
	max-width: 100vw;
}

.contentdiv {
	margin: 0px;
	margin-left: 300px;
	min-height: 100vh;
	max-height: 100vh;
	min-width: calc(100vw - 300px);
	max-width: calc(100vw - 300px);
	overflow: hidden;
	/*background: red;*/
}

.tabcontent {
	background: white;
	margin: 20px;
}

.tabcontent p {
	color: black;
}


.clicktroughtdiv {
	pointer-events: none;
}

.tabtwo {
	position: relative;
	min-height: calc(100vh - 120px);
}

.commentedit {
	position: absolute;
	align-items: center;
	bottom: 0px;
	min-width: 76%;
	max-width: 76%;
	padding: 10px;
	float:left;
}

.commenteditfield {
	xdisplay: inline;
	float:left;
	width: 79%;
}

.postcommentbutton {
	float:right;
	display: inline;
	width: 20%;
}

.commentsblock {
	min-height: calc(100vh - 150px);
	max-height: calc(100vh - 150px);
	min-width: 100%;
	max-width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.logblock {
	min-height: calc(100vh - 96px);
	max-height: calc(100vh - 96px);
	min-width: calc(100vw - 300px);
	max-width: calc(100vw - 300px);
	overflow-y: auto;
	overflow-x: hidden;
	align-items: center;
}

/* Edit blocks create sidebar */

.edit-block {
	min-height: calc(100vh - 90px);
	max-height: calc(100vh - 90px);
	min-width: 100%;
	max-width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	align-items: left;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.edit-left-block {
	min-width: 320px;
	max-width: 320px;
	min-height: calc(100vh - 90px);
	max-height: calc(100vh - 90px);
	overflow-x: hidden;
}

.edit-left-block-top {
	min-width: 100%;
	max-width: 100%;
	min-height: 100px;
	max-height: 100px;
	overflow: hidden;
	border-bottom: 1px solid #dddddd;
	padding: 10px;
}

.edit-left-block-bottom {
	min-width: 100%;
	max-width: 100%;
	min-height: calc(100vh - 194px);
	max-height: calc(100vh - 194px);
	overflow-x: hidden;
	overflow-y: auto;
	padding: 10px;
	/*background-color: green;*/
}

.edit-right-block {
	min-width: calc(100vw - 320px);
	max-width: calc(100vw - 320px);
	min-height: calc(100vh - 100px);
	max-height: calc(100vh - 100px);
	overflow-y: auto;
	overflow-x: auto;
	border-left: 1px solid #dddddd;
	padding: 10px;
	/*background-color: yellow;*/
	/*outline: 1px solid red;*/
}

.edit-segment-tools {
	min-width: calc(100vw - 660px);
	max-width: calc(100vw - 660px);
	height: auto;
	/*background-color: blue;*/
}

.edit-segment-cards {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	min-width: calc(100vw - 660px);
	max-width: calc(100vw - 660px);
	/*height: auto;*/
	/*background-color: blue;*/
}

.b-header {
	width: 100%;
	background-color: #eeeeee;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.card-ui {
	margin: 10px;
}

.card-footer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10px;
	padding: 0px;
	background-color: white;
	border: 0px;
}

.card-left {
	text-align: left;
}

.b-delete {
	width: 80px;
}

select {
	margin-left: 0px;
	/*min-width: 300px;*/
	min-width: 100%;
	max-width: 100%;
	width: 100%;
}

label {
	color: black;
	font-size: 14px;
	display: block;
	margin-top: 5px;
}


table {
	width: 90% !important;
	margin: auto;
}

.bubbleme {
	width: 65%;
	float: right;
	border:2px solid black;
	border-radius: 10px;
	padding: 15px;
	margin: 10px;
}

.bubblenotme {
	width: 65%;
	float: left;
	border:2px solid black;
	border-radius: 10px;
	padding: 15px;
	margin: 10px;
}

.bubblesend {
	float:left;
	height: 1px;
	width: 65%;
	float: left;
}






/* react-bootstrap actually used */

.logo {
	align-items: center;
	width: 150px;
	margin: auto;
	margin-top:10px;
	margin-bottom:10px;
	display:block;
}

.logo img {
	align-items: center;
	width: 150px;
	margin-top:0px;
	margin-bottom:0px;
	display:block;
}

.spinner {
	align-items: center;
	width: 40px;
	margin: auto;
	margin-top:10px;
	margin-bottom:10px;
	display:block;
}

.spinner img {
	align-items: center;
	width: 40px;
	margin-top:0px;
	margin-bottom:0px;
	display:block;
}

.container {
	background-color: #ffffff;
	font-size: 12px;
	color: white;
}

.centeredxy {
	margin-left: auto;
	margin-right: auto;
	margin-top: 20%;
	margin-bottom: auto;
	display: block;
	width: 500px;
	height: 100px;
	overflow: hidden;
}

.form-label {
	margin-top: .5rem;
}

.accordion-button {
	padding: 8px;
	font-size: 12px;
}

.toolbar {
	display: flex-direction;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #cccccc;
}

.toolbar p {
	color: black;
	margin-bottom: 0px;
	margin-top: 5px;
}

.listdiv {
	min-height: calc(100vh - 120px);
	max-height: calc(100vh - 120px);
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.listdiv p {
	color: black;
}

.detailsdiv {
	min-height: calc(100vh - 94px);
	max-height: calc(100vh - 94px);
	overflow: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.detailsdiv p {
	color: black;
}

.detailswrap {
	min-height: calc(100vh - 94px);
	max-height: calc(100vh - 94px);
	overflow: hidden;
}

.card {
}

.card-narrow {
	background-color: #ffffff;
	margin: 10px;
	max-width: 200px;
	min-width: 200px;
	width: 200px;
	min-height: auto;
	height: auto;
}

.card-wide {
	background-color: #ffffff;
	margin: 10px;
	max-width: 280px;
	min-width: 280px;
	width: 280px;
	min-height: auto;
	height: auto;
}


.card-title {
	color: black;
}

.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}









.sidebar {
	background: #afafaf;
	min-height: 100vh;
	max-height: 100vh;
	height: 100vh;
	width: 300px;
	padding: 0px;
	margin: 0px;
	text-align: center;
	z-index: 3001;
	display: flex-direction;
	float: left;
}

.sidebar p {
	color: black;
}

/* global filter divs */

.filter-wrap {
	position: relative;
	max-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
}

.filter-fixed {
	flex-grow: 0;
	flex-shrink: 0;
	/*background-color: blue;*/
}

.filter-scrolling {
	overflow-y: auto;
	flex-grow: 1;
	flex-shrink: 1;
	/*background-color: red;*/
}

.filter-clear {
	flex-grow: 0;
	flex-shrink: 0;
	/*background-color: red;*/
}

button.filter-clear {
	padding: 0px;
	margin: 0px;
}

.div-visible {
}

.div-hidden {
	xvisibility: hidden;
	display: none;
}

h2 {
	color: black;
	word-break: break-all;
}

h4 {
	font-size: 16px;
	color: black;
}

.form-check-label {
	cursor:pointer;
}

.form-check-input {
	cursor:pointer;
}

code {
	min-width: 100%;
	display: block;
	white-space: pre-wrap;
	padding: 5px;
}

.card-title.h5 {
	font-size: 1.15rem;
	margin: 3px 0px 6px;
	text-align: center;
}

.card-body {
	padding: 8px 8px 10px;
	align-items: center;
	text-align: center;
}

.card img {
	background-color: #eeeeee;
}

.wide {
	width: 300px;
}

.w100 {
	width: 100%;
}

.w100block {
	width: 100%;
	display:block;
}

.btn-link {
	text-decoration: none;
	font-size: 12px;
}

.filter-filter {
	/*background: #ff0000;*/
	text-align: left;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0px;
	padding-bottom: 20px;
}

.filter-edit {
	font-size: 1.3em;
}

.hr {
	height: 0px;
	margin: 0px;
}

hr:not([size]) {
	height: 0px;
	margin: 10px;
}

.react-datetime-picker__wrapper {
	border: none !important;
}

pre {
	font-size: 12px;
}

/*
* {
  outline: 1px solid red;
}
*/
